import $ from 'jquery';
$(function(){
	$('.js--delete_receipt').on('click', function() {
		$('.js--receipt_preview_area').remove();
		$('.js--receipt_upload_area').show();
	});

	const $loading = $('.loading');
	const $payload_error = $('.js--payload_error');
	const $payload_error_form_group = $payload_error.closest('.form-group');

	$('form')
		.on('direct-uploads:start', function() {
			$loading.show();
			$payload_error.hide();
			$payload_error_form_group.removeClass('has-error');
		})
		.on('direct-upload:error', function(e) {
			e.preventDefault();
			$loading.hide();
			$payload_error.show();
			$payload_error_form_group.addClass('has-error');
		})
	;

	$('.js--gift-select').on('click', function() {
		const $this = $(this);
		const producer_name = $this.closest('.js--gift-box').find('.js--producer_name').html();
		$('.js--producer_id').val($this.data('id'));
		$('.js--selected_producer_name').html(producer_name);
		$('.modal-bg').trigger('click');
		$('.js--producer_name_area').show();
	});
});
