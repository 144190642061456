import $ from 'jquery';
import '@fancyapps/fancybox/dist/jquery.fancybox'


$(function(){
	// var $w = $(window);

	// $('.top__intro-movie').each(function(){
	// 	var $this = $(this);
	// 	var $thumb_list = $this.find('.top__intro-movie__thumb');

	// 	$thumb_list.find('li').on('click',function(){
	// 		$thumb_list.find('li').removeClass('active');
	// 		$(this).addClass('active');
	// 		var movie_id = $(this).data('video_id');
	// 		changeVideo(movie_id);
	// 	});
	// 	function changeVideo(movie_id){
	// 		var iframeTag = $('.movie_iframe')[0],
	// 		newVideo = "?videoId="+ movie_id,
	// 		theSrc = iframeTag.src,
	// 		srcWithoutVideo = theSrc.substring( 0, theSrc.indexOf( "?" ) ),
	// 		newSrc = srcWithoutVideo + newVideo;
	// 		iframeTag.src = newSrc;
	// 	}
	// });

});
