// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("@rails/activestorage").start()
import '../stylesheets/application';

import '../tile.js'
import '../slick.js'
import '../hammer.js'
import '../common.js'
import '../route.js'
import '../vendor/shuttlerock.js'
import 'lazysizes';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../img', true)
const imagePath = (name) => images(name, true)
