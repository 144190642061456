import $ from 'jquery';
import ClipboardJS from 'clipboard';
import lazySizes from 'lazysizes';
import Cookies from 'js-cookie';
var IScroll = require('iscroll');
var Hammer = require('hammer');
$(function(){
	var $w = $(window);
	var $ww = $w.width();
	var _transitionEnd = 'oTransitionEnd mozTransitionEnd webkitTransitionEnd msTransitionEnd transitionend';

	// 画像切替
	var replaceWidth = 768;
	$('.js-img').each(function() {
		var $this = $(this);
		function imgSize() {
			if($w.innerWidth() >= replaceWidth) {
				$this.attr('src', $this.data('pc_src'));
			} else {
				$this.attr('src', $this.data('sp_src'));
			}
		}
		$w.on('resize',function() {
			imgSize();
		});
		imgSize();
	});

	// メニュー
	var state = false;
	var scrollpos;
	$('.header__menu-btn').on('click',function(){
		$('.header__logo').addClass('off');
		$('header').toggleClass('open');
		$('.header__menu').toggleClass('active').on(_transitionEnd,function(){
			if(!$('.header__menu').hasClass('active')){
				$('.header__logo').removeClass('off');
			}

		});
		if(state == false) {
			scrollpos = $w.scrollTop();
			$('body').addClass('fixed').css({'top': -scrollpos});
			state = true;
		} else {
			$('body').removeClass('fixed').css({'top': 0});
			window.scrollTo( 0 , scrollpos );
			state = false;
		}
	});
	$('.header__menu ul li a').on('click',function(){
		$('.header__menu').removeClass('active').on(_transitionEnd,function(){
			$('header').removeClass('open');
		});
		$('body').removeClass('fixed').css({'top': 0});
		window.scrollTo( 0 , scrollpos );
		state = false;
	});

	// ページ内アンカーと外部遷移
	if(location.hash) {
		// ページ内リンクで遷移した際に、lazyLoadの影響で座標がずれてしまうため、Lazyloadを解除
		const lazyLoadList = $('.lazyload');
		$.each(lazyLoadList, function(i, el) {
			$(el).attr('src', $(el).attr('data-src'));
			$(el).removeAttr('data-src');
		})
	}
	$w.on('load', function() {
		var urlHash = location.hash;
		if(urlHash) {
			$('body,html').stop().scrollTop(0);
			setTimeout(function () {
				scrollToAnker(urlHash) ;
			}, 100);
		}
	})
	$('a[href^="#"]').on('click',function() {
		var href= $(this).attr("href");
		var hash = href == "#" || href == "" ? 'html' : href;
		scrollToAnker(hash);
		return false;
	});
	function scrollToAnker(hash) {
		var headerHight = $('header').innerHeight();
		var target = $(hash);
		var position = target.offset().top-headerHight;
		$('body,html').stop().animate({scrollTop:position}, 400);
	}

	// 追従ボタン
	$w.on('scroll load', function() {
		if ($(this).scrollTop() > 50) {
			$('.fixed__link').addClass('show');
		} else {
			$('.fixed__link').removeClass('show');
		}
	});


	// FAQ
	$(".faq-list .faq-title").on("click", function() {
		$(this).next().slideToggle();
		$(this).toggleClass("active")
	});


	// MODAL
	var scrollPos;
	var modal_flag = false;
	$('.modal-btn').on("click", function() {
		scrollPos = $(window).scrollTop();
		var target = $(this).data('target');
		if($(this).data('target_num')){
			target_num = $(this).data('target_num');
		}
		if($(this).data('tab')){
			target_tab = $(this).data('tab');
		}
		$('.modal.'+target).fadeIn(400,function(){
				modal_flag = true;

			}
		);
		$('body').addClass('fixed').css({ top: -scrollPos });
		return false;
	});
	$('.modal-bg, .js-close').on("click", function() {
		$('.modal').fadeOut();
		$('body').removeClass('fixed').css({ top: '' });
		$(window).scrollTop(scrollPos);
		modal_flag = false;
		return false;
	});


	$('.js-count').each(function() {
		var $this = $(this);
		var $target = $($this.data('target'));
		var max_length = $this.data('max_length');

		$target.on('keyup', modify_charcounter);
		$target.on('keydown', modify_charcounter);
		$target.on('mouseover', modify_charcounter);
		setInterval(modify_charcounter, 200);

		function modify_charcounter() {
			var str = $target.val();
			// http://teppeis.hatenablog.com/entry/2014/01/surrogate-pair-in-javascript
			var str_length = str.length - (str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g)||[]).length
			if ( $this.text() != str_length ) {
				$this.text(str_length);
			}
			if ( str_length > max_length ){
				$this.addClass('form-count-over');
			}
			else {
				$this.removeClass('form-count-over');
			}
		}
	});



	var gift_scroll;

	const cookieKey = 'oill'
	$('.cookie').each(function(){
		if(!Cookies.get(cookieKey)){
			$('.cookie').addClass('view');
			addCookieClass();
			spSetCookieOffset();
			var is_view = true;
			$w.on('load resize',function(){
				if(is_view){
					var mt = $('.cookie').height();
					$('.top__main').css({'margin-top':mt + 'px'});
				}
			});
			$('.js__cookie-btn').on('click',function(){
				$('.cookie').hide();
				$('.top__main').css({'margin-top':'0'});
				removeCookieClass()
				spRemoveCookieOffset()
				is_view = false;
				Cookies.set(cookieKey, '1');
			});
		};
	});
	$w.on('resize', function() {
		if(!Cookies.get(cookieKey)){
			spSetCookieOffset();
		} else {
			spRemoveCookieOffset();
		}
	})

	function addCookieClass() {
		$('.wrap, header, .header__menu-btn, .header__menu').addClass('is_cookie');
	}
	function removeCookieClass() {
		$('.wrap, header, .header__menu-btn, .header__menu').removeClass('is_cookie');
	}

	function spSetCookieOffset() {
		if(window.matchMedia("screen and (max-width: 767px").matches) {
			const cookieHeight = parseInt($('.cookie').height())
			$('.header__logo, header').css({
				top: cookieHeight + 'px'
			});
			$('.header__menu__bottom').css({
				'padding-bottom': 80 + cookieHeight + 'px'
			});
		} else {
			$('.header__logo, header').css({
				top: ""
			});
			$('.header__menu__bottom').css({
				'padding-bottom': ""
			});
		}
	}
	function spRemoveCookieOffset() {
		if(window.matchMedia("screen and (max-width: 767px").matches) {
			$('.header__logo, header').css({
				top: ""
			});
			$('.header__menu__bottom').css({
				'padding-bottom': ""
			});
		}
	}
});
